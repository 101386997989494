@use "reset";
@use "variable" as v;

$bg-img-url : "../images/background-mosaic.jpg";

$colors : ("primary" : #006994,
    "secondary" : #911D00,
    "tertiary" : #009130,
);

@each $key,
$value in $colors {
    .bg-#{$key} {
        background-color: $value;
    }
}

@keyframes hithere {
    30% {
        transform: scale(1.1);
    }

    40%,
    60% {
        transform: rotate(-10deg) scale(1.1);
    }

    50% {
        transform: rotate(10deg) scale(1.1);
    }

    70% {
        transform: rotate(0deg) scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

body {
    background-image: linear-gradient(to bottom, rgba(255, 255, 0, 0.5), rgba(0, 0, 255, 0.5)), url($bg-img-url);
    position: relative;
    min-height: 100vh;

    .container {
        max-width: 1600px;
        margin: 0 auto;
        padding: 1rem 2rem;
    }

    header {
        text-align: center;

        div {
            background-color: rgba(75, 75, 75, 0.5);
            // margin: 1rem;
            border-radius: 0.5rem;
            box-shadow: 0px 5px 15px #0000008a;
            overflow: hidden;

            img {
                width: 17rem;

                &:hover {
                    animation: hithere 1s ease infinite;
                    cursor: pointer;
                }
            }
        }

    }

    footer {
        position: absolute;
        width: 100%;
        bottom: 0;
        background: rgba(145, 29, 0, 0.70);
        color: white;
        height: 7rem;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    #content-wrap {
        padding-bottom: 7rem;
        /* Footer height */
    }

    .menu-wrapper {
        text-align: center;

        @media screen and (min-width: v.$md-breakpoint) {
            display: flex;
            justify-content: space-around;
        }

        .menu-item {
            padding: 3rem;
            flex-basis: 32%;
            // border: solid white 0.2rem;
            border-radius: 1rem;
            margin-bottom: 1rem;

            &:hover {
                box-shadow: 0px 5px 15px #0000008a;
                cursor: pointer;
            }

            span {
                font-size: 2rem;
                text-transform: uppercase;
                color: white;
            }

            p {
                color: white;
            }
        }
    }

    .order {
        @media screen and (min-width: v.$md-breakpoint) {
            display: flex;
        }

        .pizzas {
            flex-basis: 70%;
            gap: 0.5rem;

            @media screen and (min-width: v.$md-breakpoint) {
                display: flex;
                justify-content: left;
                flex-wrap: wrap;
            }

            .pizza {
                flex-basis: 49%;
                background-color: white;
                color: black;
                // margin-bottom: 0.5rem;
                padding: 0.5rem;
                border-radius: 0.5rem;

                h3 {
                    text-transform: uppercase;
                    font-weight: bold;
                }

                span {
                    font-size: 3rem;
                }

                img {
                    width: 100%;
                }

                div {
                    display: flex;
                    justify-content: space-between;
                    align-items: baseline;
                }
            }
        }

        .orderDetails {
            flex-basis: 30%;
        }
    }

    .orderDetails {
        padding: 0.5rem;
        border-radius: 0.5rem;
        background-color: white;

        h3 {
            margin: 0.5rem;
        }

        p {
            // prix
            background-color: #911D00;
            color: white;
            text-align: center;
            width: 100%;
            padding: 0.5rem;
            border-radius: 1rem;

            span {
                font-size: 1.5rem;
            }
        }

        .pizzaChoice {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .optionOrder {
            display: flex;
            justify-content: center;
            gap: 0.5rem;
            margin-top: 1rem;
            margin-bottom: 1rem;

            button {
                background-color: #911D00;
                color: white;
                text-align: center;
                width: 100%;
                padding: 0.5rem;
                border: none;
                border-radius: 1rem;
            }

            .paid {
                &:hover {
                    background-color: #84a184;
                    cursor: pointer;
                }

                background-color: #3C7D3C;
            }
        }
    }

    .listOrder {
        background-color: white;
        padding: 0.5rem;
        border-radius: 0.5rem;


        table {
            width: 100%;
            text-align: left;
            border-collapse: collapse;
        }

        thead {
            border-bottom: 1px solid black;
        }

        td {
            padding: 1rem;
            height: 3rem;
        }

        .actionOrder {
            text-align: center;
            cursor: pointer;

            a {
                text-decoration: none;
                color: black;
            }
        }

        td.paid {
            background-color: #3C7D3C;
            color: white;
        }

        td.notPaid {
            background-color: #E69B05;
            color: white;
        }

        tr:hover {
            background-color: rgb(218, 218, 218);
        }
    }

    .choiceOrder {
        background-color: white;
        border-radius: 0.5rem;
        // text-align: center;
        margin-bottom: 1rem;
        padding: 1rem;

        display: flex;
        justify-content: center
    }
}