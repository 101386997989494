%no-margin {
    margin: 0;
    padding: 0;
}

html {
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    //Application de l'extension no-margin
    @extend %no-margin;
}

* {
    box-sizing: border-box;
}